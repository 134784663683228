<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Configurações Gerais do Sistema</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Qtde Dias Expiração Cotação para Distribuidor</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.qtdeDiasExpiracaoCotacaoDistribuidor" 
                        :rules=[validations.required]
                        v-mask="'#####'"
                        suffix="dias"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Qtde Dias Expiração Cotação para usuários internos</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.qtdeDiasExpiracaoCotacaoInterno" 
                        :rules=[validations.required]
                        v-mask="'#####'"
                        suffix="dias"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Configurações para Solicitação de Cadastro de Produtos'" />
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Informe o e-mail que irá receber a solicitação</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.emailSolicitacaoCadastroProduto" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Título do email</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.tituloSolicitacaoCadastroProduto" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Descrição do corpo do email</label>
                    <v-textarea
                        v-model="configuracaoSistemaRequest.descricaoSolicitacaoCadastroProduto"
                        single-line
                        rows="4"
                        row-height="30"
                        outlined
                    >
                    </v-textarea>
                    <p style="font-weight: normal !important; margin-top: -20px; font-size: 10px;">
                        Parametros disponíveis para configuração:
                        <br />
                        <br />
                        ${nome-solicitante}: Nome do solicitante (usuário logado no sistema)
                        <br />
                        ${codigo-produto}: Código do produto da busca do produto
                    </p>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Certificação EX'" />
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Valor</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.valorCertificacaoEX"
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Qtde Máxima</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.qtdeMaximaCertificacaoEX" 
                        :rules=[validations.required]
                        v-mask="'#####'"
                        suffix="peças"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Prazo Reincidência</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.prazoReincidenciaCertificacaoEX" 
                        :rules=[validations.required]
                        v-mask="'#####'"
                        suffix="dias"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="configuracaoSistemaRequest"
                :loading="loading"
                :showButtons="{
                    cancel: false,
                    save: true
                }"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import { formaterDecimalBRServer } from '@/utilities/Utils';

    export default ({

        mixins: [Helpers],

        components: {
            ContentHeader,
            ActionButtons
        },

        data: () => ({

            id: 1,

            validForm: true,

            configuracaoSistemaRequest: {
                id: 0,
                qtdeDiasExpiracaoCotacaoDistribuidor: 0,
                qtdeDiasExpiracaoCotacaoInterno: 0,
                emailSolicitacaoCadastroProduto: '',
                tituloSolicitacaoCadastroProduto: '',
                descricaoSolicitacaoCadastroProduto: '',
                valorCertificacaoEX: 'R$ 0,00',
                qtdeMaximaCertificacaoEX: 0,
                prazoReincidenciaCertificacaoEX: 0
            },

            moneyFormat: {
                prefix: `R$ `,
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },

            loading: false,
            
            validations: {
                required: required,
            },
        }),

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {
                    const response = await this.$store.dispatch("configuracaoSistemaModule/GetById", this.id);

                    if (response.success) {
                        this.configuracaoSistemaRequest = response.result;
                        this.configuracaoSistemaRequest.valorCertificacaoEX = `R$ ${this.configuracaoSistemaRequest.valorCertificacaoEXFormatted}`;
                    }
                }
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.configuracaoSistemaRequest.id = this.id;
                    this.configuracaoSistemaRequest.valorCertificacaoEX = formaterDecimalBRServer(this.configuracaoSistemaRequest.valorCertificacaoEX);

                    const result = await this.$store.dispatch("configuracaoSistemaModule/CreateUpdate", this.configuracaoSistemaRequest);

                    if (result.success === true) {
                        this.showToast("success", "Sucesso!", result.message);
                        this.getRegister();
                    }
                    else {
                        this.showToast("error", "Aviso!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>



<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>